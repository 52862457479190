<template>
    <div class="card mb-2">
        <div class="card-body px-2 py-0" style="max-height: 50vh; overflow-y: auto;">
            <div class="d-flex justify-content-between w-100">
                <div>
                    <div class="fw-bold py-0 my-0">
                        Definition: {{id}}
                    </div>
                    <div class="ms-4" v-html="descr1"></div>
                </div>
                <div class="text-end"><a href="javascript:;;" class="nav-link h4" @click="$emit('closed')">&times;</a></div>
            </div>
            <div class="fw-bold mt-2">Related Verses: <small><i>({{relatedvs.length.toLocaleString()}} found)</i></small></div>
            <div ref="divRelatedVs">
                <ol class="ms-4 previewing" v-if="previewing">
                    <li v-for="(item,index) in sortedRelatedvs2" :key="index" v-html="item.text"></li>
                </ol>
                <ol class="ms-4" v-else>
                    <li v-for="(item,index) in sortedRelatedvs" :key="index" v-html="item.text"></li>
                </ol>
            </div>
        </div>
        <div class="card-footer">
            <div v-if="previewing">
                <button class="btn btn-primary align-self-center me-2" @click="commit">Replace</button>
                <button class="btn btn-danger align-self-center" @click="cancelPreview">Cancel</button>
            </div>
            <div v-else class="d-flex flex-wrap">
                <input type="text" class="form-control me-2" placeholder="assign word" v-model="replacement" style="max-width: 15rem;" />
                <button class="btn btn-primary align-self-center me-2" @click="preview" :disabled="!replacement">Preview Replace</button>
                <button class="btn btn-danger align-self-center" @click="$emit('closed')">Cancel</button>
            </div>
        </div>
    </div>
</template>
<style>
    .previewing .badge.bg-warning {
        text-decoration: line-through;
    }
</style>
<script>
    const _ = require('lodash');
    export default {
        props: {
            id: String
        },
        computed: {
            sortedRelatedvs() { return _.orderBy(this.relatedvs, ['bookId'], ['asc']); },
            sortedRelatedvs2() { return _.orderBy(this.relatedvs2, ['bookId'], ['asc']); }
        },
        data() {
            return {
                descr1: null,
                relatedvs: [],
                relatedvs2: [],
                hebrews: [],
                greeks: [],
                previewing: false,
                replacement: null,
                booknames: ["Genesis", "Exodus", "Leviticus", "Numbers", "Deuteronomy", "Joshua", "Judges", "Ruth", "1 Samuel", "2 Samuel", "1 Kings", "2 Kings", "1 Chronicles", "2 Chronicles", "Ezra", "Nehemiah", "Esther", "Job", "Psalms", "Proverbs", "Ecclesiastes", "Song of Solomon", "Isaiah", "Jeremiah", "Lamentations", "Ezekiel", "Daniel", "Hosea", "Joel", "Amos", "Obadiah", "Jonah", "Micah", "Nahum", "Habakkuk", "Zephaniah", "Haggai", "Zechariah", "Malachi", "Matthew", "Mark", "Luke", "John", "Acts", "Romans", "1 Corinthians", "2 Corinthians", "Galatians", "Ephesians", "Philippians", "Colossians", "1 Thessalonians", "2 Thessalonians", "1 Timothy", "2 Timothy", "Titus", "Philemon", "Hebrews", "James", "1 Peter", "2 Peter", "1 John", "2 John", "3 John", "Jude", "Revelation"],
            };
        },
        async mounted() {
            const vm = this;
            await vm.loadDicts();
        },
        methods: {
            commit() {
                const vm = this;
                let customs = localStorage.getItem('customs');
                if (!customs) customs = "";
                customs = _.join(_.sortedUniq(_.filter(_.split(customs, '\n'), x => x && !x.match("^" + vm.id + ','))), '\n') + '\n';
                customs += `${vm.id},${vm.replacement}\n`;
                localStorage.setItem('customs', customs);
                vm.replacement = null;
                vm.cancelPreview();
                vm.$emit('closed');
            },
            cancelPreview() {
                const vm = this;
                vm.previewing = false;
            },
            preview() {
                const vm = this;
                vm.relatedvs2 = _.map(vm.relatedvs, x => { return { bookId: x.bookId, text: _.replace(x.text, `</span><sup`, `</span><span class='badge bg-success'>${vm.replacement}</span><sup`) } });
                vm.previewing = true;
            },
            async loadDescr(id) {
                const vm = this;
                const prefix = id.slice(0, 1).toLowerCase();
                const dictName = prefix === "h" ? "hebrew.txt" : "greek.txt";
                const refNum = +id.replace(/^./, '');
                vm.descr1 = null;
                const line = _.split((await (await fetch(`/res/${dictName}`)).text()), '\n').find(x => x.match('^' + refNum.toString() + ''));
                const vals = _.split(line, '\t')
                vm.descr1 = vals[1] + "<br />" + vals[2];
                vm.replacement = vals[1].split('<BR>')[prefix === "h" ? 0 : 1];
            },
            async getRelatedVerses(id) {
                const vm = this;
                const prefix = id.slice(0, 1).toLowerCase();
                const books = prefix === "h" ? vm.booknames.slice(0, 39) : vm.booknames.slice(39, 66);
                const refNum = +id.replace(/^./, '');
                vm.relatedvs = [];
                books.forEach(async function(book) {
                    const bookId = (vm.booknames.indexOf(book) + 1).toString().padStart(2, '0');
                    const lines = _.split((await (await fetch(`/res/${bookId}.txt`)).text()), '\n');
                    var matchedLines = lines.filter(line => (_.split(line, '\t')[2]) && (_.split(line, '\t')[2]).match('\\D' + refNum + '\\D'))
                    if (!(matchedLines && matchedLines.length)) return;
                    matchedLines.forEach(m => {
                        const vals = _.split(m, '\t');
                        vm.relatedvs.push({ bookId: +bookId, text: `<div class='d-none'>${bookId}</div><div><a href='/Book/${bookId}/${vals[1].replace(':', '_')}' target='_blank'><b>${book} ${vals[1]}</b></a>: ${vm.addsuprefs(vals[2], bookId, refNum)}</div>` });
                    });
                });
            },
            addsuprefs(val, id, refNum) {
                const prefix = +id < 40 ? "H" : "G";
                let tmpVal = _.replace(val, /\(?(\d+)\)?,?/gi, `<sup class='detail2'>${prefix}${'$1'}</sup>`);
                const exp1 = `(\\S+)(\\s*<sup class='detail2'>[HG]\\d+</sup>\\s)*<sup class='detail2'>${prefix}${refNum}</sup>`;
                const re = new RegExp(exp1, "gi");
                return _.replace(tmpVal, re, `<span class='badge bg-warning'>${'$1'}</span>${'$2'}<sup class='detail2'>${prefix}${refNum}</sup>`);
            },
            async loadDicts() {
                const vm = this;
                if (vm.hebrews.length && vm.greeks.length) return;
                vm.hebrews = _.split((await (await fetch(`/res/hebrew.txt`)).text()), '\n');
                vm.greeks = _.split((await (await fetch(`/res/greek.txt`)).text()), '\n');
            },
        },
        watch: {
            id: {
                immediate: true,
                async handler(nv, ov) {
                    if (nv === ov || !nv) return;
                    const vm = this;
                    await vm.loadDescr(nv);
                    await vm.getRelatedVerses(nv);
                }
            }
        }
    }
</script>